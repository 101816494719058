export enum PaymentStatus {
  "DISCONNECTED" = 1,
  "READY" = 0,
  "PAYMENT_REQUESTED" = 100,
  "PAYMENT_PROCESSING" = 150,
  "PAYMENT_SUCCESS" = 200,
  "PAYMENT_REJECTED" = -200,
  "PAYMENT_CANCELLED" = -300,
  "PAYMENT_FAILED" = -400,

  // Fejl hos Loomis
  "DEVICE_ERROR" = -600,

  // Fejl hos HAL
  "STATE_ERROR" = -800,
  "CODE_ERROR" = -900,
}

export interface PaymentSuccessResult {
  paymentReference: string;
  paymentMethod: string;
  cardTransactionData: {
    card: {
      maskPan: string;
      type: string;
      expiryDate: string;
      scheme: string;
    };
  };
}

export interface PaymentProcessingResponse {
  isSuccess: boolean;
  statusCode: PaymentStatus;
  statusName: string;
  transactionId?: string;
  lastHeartbeatReceived: string;
  paymentResult?: { successData?: PaymentSuccessResult };
}
